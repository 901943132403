import React from 'react'

const Quiz = () => {
  return (
    <div>
      Quiz Page
    </div>
  )
}

export default Quiz
