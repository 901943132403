import React from 'react';
import "./Navbar.css";
import { Link } from "react-router-dom";


const Navbar = (props) => {
  const { type } = props;
  return (
    <div className="navbar">
      <div className="logoContainer">
        <Link className="links" to="/">
          <div className="logo">AIRBUS</div>
        </Link>
      </div>
      {type === "user" ? (
        <div className="navbarButtonContainer">
          <Link className="links" to="/quiz">
            Quiz
          </Link>
          <Link className="links" to="/polling">
            Live Poll
          </Link>
          <Link className="links" to="/about">
            About
          </Link>
          <Link className="links" to="/userProfile">
            <div className="userAvatair">Y</div>
          </Link>
        </div>
      ) : (
        <div className="navbarButtonContainer">
          <Link to="/createQuiz" className="links">
            Create Quiz
          </Link>
          <Link to="/createPoll" className="links">
            Create Live Poll
          </Link>
          <Link to="/about" className="links">
            About
          </Link>
          <Link className="links" to="/userProfile">
            <div className="userAvatair">Y</div>
          </Link>
        </div>
      )}
    </div>
  );
};

export default Navbar;
