import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

const UserEventDetailsDialog = (props) => {
  const { open, onClose, eventObject } = props;

  const handleClose = () => {
    onClose(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>User Event Details</DialogTitle>
      <DialogContent>
        <Typography>
          <div>Id: {eventObject.id}</div>
          <div>Title: {eventObject.title}</div>
          <div>Time: {eventObject.time}</div>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserEventDetailsDialog;
