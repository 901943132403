import React, { Component } from 'react'
import { IconButton } from '@mui/material'
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import PollOutlinedIcon from '@mui/icons-material/PollOutlined';
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';

export class HomeContent extends Component {
  render() {
    return (
      <div style={{marginLeft:'100px', marginRight:'100px', background:'#b7c9d3'}}>
        <div style={{justifyContent:'left'}}>
            <div style={{justifyContent:'left'}}>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    sx={{ mr: 2 }}>
                    <CloudQueueIcon/>
                </IconButton>
            </div>
            <div>
                WORDCLOUD <br/> Quick and Easy Way to Visualize<br/> Audience's  Ideas and Thoughts
            </div>
        </div>
        <div>
            <div>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    sx={{ mr: 2 }}>
                    <AssignmentOutlinedIcon/>
                </IconButton>
            </div>
            <div>
                Survey <br/> Capture Voices and Opinions<br/> of your Audience.
            </div>
        </div>
        <div>
            <div>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    sx={{ mr: 2 }}>
                    <QuizOutlinedIcon/>
                </IconButton>
            </div>
            <div>
                Quiz <br/> Entertaining, Dynamic<br/> and Factual Quizzes for your<br/> Audience. 
            </div>
        </div>
        <div>
            <div>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    sx={{ mr: 2 }}>
                    <PollOutlinedIcon/>
                </IconButton>
            </div>
            <div>
                Livepoll <br/> Get Live Feedback and Make <br/> Collective Decision Effectively
            </div>
        </div>
        <div>
            <div>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    sx={{ mr: 2 }}>
                    <QuestionMarkOutlinedIcon/>
                </IconButton>
            </div>
            <div>
                QnA <br/> Run QnAs the Way You Want <br/> with Anonymity or More.
            </div>
        </div>
      </div>
    )
  }
}

export default HomeContent