import { useEffect, useState } from "react";
import image from "../../images/userImage.png";
import classes from './UserProfileCard.module.css';
import { CircularProgress } from "@mui/material";




const UserProfileCard = (props) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setHasError(false);
      try {
        const res = await fetch('https://enquete.airbus-v.com/secured/api/userInfo');
        const userData = await res.json();
        //console.log(res.data)
        setData(userData[0]);
      } catch (error) {
        setHasError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [setData]);
  return (<div>
    {
      hasError ? <p>Something went wrong.</p> :
        isLoading ? (
          <div>
            <div className="empty-container"></div>
            <center>
              <CircularProgress />
            </center>
            <div className="empty-container"></div>
          </div>
        ) : (
            <div className={classes.userProfileCard}>
              <div className={classes.userImage}>
                <img src={image} alt="User" />
              </div>
              <div className={classes.userInfo}>
                <div>Name: {data.name}</div>
                <div>Team/Siglum: {data.siglum}</div>
                <div>Designation</div>
              </div>
            </div>
          )
    }
  </div>

  );
};

export default UserProfileCard;