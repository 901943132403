import React, { useState } from "react";
import { Button } from "@mui/material";
import classes from "./Poll.module.css";
const Poll = (props) => {
  //const { pollQuestion, setPollAns } = props;

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState(new Map());
  const [isSubmitted, setIsSubmitted] = useState(false);
  const questions = [
    {
      id: 1,
      question: "Would you like to earn a million dollars?",
      options: ["Yes", "No"],
      optionType: "single",
    },
    {
      id: 2,
      question: "Which cities have you stayed in?",
      options: ["Bangalore", "Mumbai", "Delhi", "Chennai", "Indore"],
      optionType: "multiple",
    },
    {
      id: 3,
      question: "How often do you want to come to office?",
      options: [
        "Twice a week",
        "Once a Week",
        "Twice a month",
        "Permanent Wfh",
      ],
      optionType: "single",
    },
  ];


  //questions is just a temporary source, we will be fetching the questions properly later.

  const handleNext = () => {
    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
  };

  const handlePrevious = () => {
    setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
  };

  const handleAnswerChange = (id, selectedAnswers) => {
    const updatedAnswers = new Map(answers);
    updatedAnswers.set(id, selectedAnswers);
    setAnswers(updatedAnswers);
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted with answers", answers);
    setIsSubmitted(true);
    // We would send the response to the backend from here.
  };

  const currentQuestion = questions[currentQuestionIndex];

  return (
    <>
      {!isSubmitted && (
        <div className={classes.container}>
          <h2>Live Polls</h2>
          <div className={classes.questionBody}>
            <h3>{currentQuestion.question}</h3>
            {currentQuestion.options.map((option, index) => (
              <div className={classes.pollBody} key={index}>
                {currentQuestion.optionType === "single" && (
                  <input
                    type="radio"
                    id={`option-${index}`}
                    name={`option-${index}`}
                    value={option}
                    checked={answers.get(currentQuestion.id) === option}
                    onChange={(event) => handleAnswerChange(currentQuestion.id, event.target.value)}
                  />
                )}
                {currentQuestion.optionType === "multiple" && (
                  <input
                    type="checkbox"
                    id={`option-${index}`}
                    name={`option-${index}`}
                    value={option}
                    checked={answers.get(currentQuestion.id)?.includes(option) || false}
                    onChange={(event) => {
                      const selectedAnswers = event.target.checked
                        ? [...(answers.get(currentQuestion.id) || []), option]
                        : (answers.get(currentQuestion.id) || []).filter((answer) => answer !== option);
                      handleAnswerChange(currentQuestion.id, selectedAnswers);
                    }}
                  />
                )}
                <label htmlFor={`option-${index}`}>{option}</label>
              </div>
            ))}
          </div>

          <div className={classes.buttons}>
            <Button
              onClick={handlePrevious}
              disabled={currentQuestionIndex === 0}
              variant="contained"
            >
              Previous
            </Button>

            {currentQuestionIndex === questions.length - 1 ? (
              <Button
                onClick={handleSubmit}
                disabled={
                  answers.size < questions.length
                }
                variant="contained"
                color="success"
              >
                Submit
              </Button>
            ) : (
              <Button onClick={handleNext} variant="contained">
                Next
              </Button>
            )}
          </div>
        </div>
      )}
      {isSubmitted && (
        <div className={classes.submitCard}>
          <h3>Your response has been recorded!</h3>
        </div>
      )}
    </>
  );
};

export default Poll;
