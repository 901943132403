import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./Home.css";

import Navbar from "../../components/navbar/Navbar";
import About from "../about/About";
import Quiz from "../quiz/Quiz";
import UserProfile from "../userProfile/UserProfile";
import CreateQuiz from "../admin/createQuiz/CreateQuiz";
import CreatePoll from "../admin/createPoll/CreatePoll";
import Homepage from "../homepage/Homepage";
import Poll from "../polling/Poll";

const Home = () => {
  //  const type = "user";
  //  const type = "admin";
  const type = "user";

  const [pollQuestion, setPollQuestion] = useState({
    question: "question",
    option1: "option1",
    option2: "option2",
    option3: "option3",
    option4: "option4",
  });

  const [pollAns, setPollAns] = useState(0);

  return (
    <>
      <Router>
        <Navbar type={type} />
        <Routes>
          <Route exact path="/" element={<Homepage />} />
          <Route path="/quiz" element={<Quiz />} />
          <Route path="/polling" element={<Poll />} />
          <Route path="/about" element={<About />} />
          <Route path="/admin/userProfile" element={<UserProfile />} />
          <Route path="/admin/createQuiz" element={<CreateQuiz />} />
          <Route
            path="/admin/createPoll"
            element={
              <CreatePoll
                pollQuestion={pollQuestion}
                setPollQuestion={setPollQuestion}
                pollAns={pollAns}
              />
            }
          />
        </Routes>
      </Router>
    </>
  );
};

export default Home;
