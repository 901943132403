import React, { Component } from 'react';
import { Toolbar, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import QrCodeIcon from '@mui/icons-material/QrCode';
import HomeContent from './HomeContent';
  
export default class Homepage extends Component {
  codeEnter = () => {
    console.log(document.getElementById('sessionCode'))
  }
  render() {
    return (
      <div>
        <div>
            <Toolbar display={'flex'} height={'80'} style={{justifyContent:'center',color: '#ffffff', background: '#00205B'}}>
              <div>
                <span>HELLO USER! WELCOME TO ENQUETE</span><br/>
                  To join a live session : <t/><t/><t/>
                  <input id='sessionCode'  type='text' value={"Enter your code here"}/> <t/><t/><t/>
                  or <t/><t/><t/>
                  <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    sx={{ mr: 2 }}>
                    <QrCodeIcon />
                  </IconButton>
              </div>
            </Toolbar>
            <Toolbar display = {'flex'} height = {'60'} style={{paddingTop:'4' ,justifyContent: 'center', color: '#00205B'}}>
              <div>
                <Typography variant='h6'>
                  Create your OWN
                </Typography>
              </div>
            </Toolbar>
            <HomeContent/>
        </div>
      </div>
    )
  }
}