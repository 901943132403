import React from 'react'
import QRCode from "react-qr-code";

import "./CreatePoll.css";
import { BarChart, Bar, XAxis, YAxis } from "recharts";
import Option from '../../../components/question/Option';

const CreatePoll = (props) => {
  const { pollQuestion } = props;

  const data = [
    { options: pollQuestion.option1, ans: 4 },
    { options: pollQuestion.option2, ans: 0 },
    { options: pollQuestion.option3, ans: 20 },
    { options: pollQuestion.option4, ans: 10 },
  ];

  const value = "www.google.com";


  return (
    <>
      <Option />
      <div className="container">
        <button>Create Poll</button>

        <div className="qrContainer">
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={value}
            viewBox={`0 0 256 256`}
          />
        </div>

        <h2>Live Polls </h2>
        <h4>{pollQuestion.question}</h4>
        <div>
          <BarChart width={400} height={300} data={data}>
            <Bar dataKey="ans" fill="#e7e7e7" />
            {/* <CartesianGrid stroke="#ccc" /> */}
            <XAxis dataKey="options" />
            <YAxis />
          </BarChart>
        </div>
      </div>
    </>
  );
}

export default CreatePoll
