import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";

const LoadMoreEventsDialog = (props) => {
  const { onClose, open } = props;

  const handleClose = () => {
    onClose(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Show More events</DialogTitle>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default LoadMoreEventsDialog;
