import { useState } from "react";
import PropTypes from "prop-types";
import {
  Tabs,
  Tab,
  Typography,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
} from "@mui/material";
import classes from "./UserHistoryCard.module.css";
import LoadMoreEventsDialog from "./LoadUserHistoryDialog";
import UserEventDetailsDialog from "./UserEventDetailsDialog";
const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const UserHistoryCardTabs = (props) => {
  const initializeObj = { id: "", title: "", time: "" };

  const [tabValue, setTabValue] = useState(0);
  const [userEventDialogOpen, setUserEventDialogOpen] = useState(false);
  const [userEventObject, setUserEventObject] = useState(initializeObj);

  const handleUserEventDialogOpen = () => {
    setUserEventDialogOpen(true);
  };

  const handleUserEventDialogClose = () => {
    setUserEventDialogOpen(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleClick = (eventObject) => {
    console.log("eventObject: ", eventObject);
    handleUserEventDialogOpen(true);
    setUserEventObject(eventObject);
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="basic tabs example"
            centered
          >
            <Tab label="Events Attended" {...a11yProps(0)} />
            <Tab label="Events Hosted " {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={tabValue} index={0}>
          <div className={classes.historyItemList}>
            {props.attendedHistoryList.map((item, index) => {
              return (
                <Card
                  className={classes.historyItemCard}
                  key={item.id}
                  onClick={() => handleClick(item)}
                >
                  <CardActionArea>
                    <CardContent>
                      <Typography>
                        <div>Id: {item.id}</div>
                        <div>Title: {item.title}</div>
                        <div>Time: {item.time}</div>
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              );
            })}
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1}>
          <div className={classes.historyItemList}>
            {props.hostedHistoryList.map((item, index) => {
              return (
                <Card
                  className={classes.historyItemCard}
                  key={item.id}
                  onClick={() => handleClick(item)}
                >
                  <CardActionArea>
                    <CardContent>
                      <Typography>
                        <div>Id: {item.id}</div>
                        <div>Title: {item.title}</div>
                        <div>Time: {item.time}</div>
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              );
            })}
          </div>
        </CustomTabPanel>
      </Box>
      <UserEventDetailsDialog
        open={userEventDialogOpen}
        onClose={handleUserEventDialogClose}
        eventObject={userEventObject}
      />
    </>
  );
};

const UserHistoryCard = (props) => {
  const [userHistoryDialogOpen, setUserHistoryDialogOpen] = useState(false);

  const handleUserHistoryDialogOpen = () => {
    setUserHistoryDialogOpen(true);
  };

  const handleUserHistoryDialogClose = () => {
    setUserHistoryDialogOpen(false);
  };

  return (
    <div className={classes.userHistoryCard}>
      <div>Activities of last 30 days</div>
      <UserHistoryCardTabs
        attendedHistoryList={props.attendedHistoryList}
        hostedHistoryList={props.hostedHistoryList}
      />
      <Button variant="contained" onClick={handleUserHistoryDialogOpen}>
        Load More
      </Button>
      <LoadMoreEventsDialog
        open={userHistoryDialogOpen}
        onClose={handleUserHistoryDialogClose}
      />
    </div>
  );
};

export default UserHistoryCard;
