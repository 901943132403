import React from "react";
import { Toolbar } from "@mui/material";
import classes from "../userProfile/UserProfile.module.css";
import UserHistoryCard from "./UserHistoryCard";
import UserProfileCard from "./UserProfileCard";

const eventAttendedList = [
  {
    id: "UYGNUYGNuy786tNUHG",
    title: "Know the World of Airbus",
    time: "25th Aug, 2023",
  },
  {
    id: "UiujiunoIBoiubo2ijb",
    title: "Townhall",
    time: "4th July, 2023",
  },
  {
    id: "ILboiubOBYy3iukhbii7",
    title: "Indigo Celebration and Address by Remi Maillard",
    time: "20th Aug, 2023",
  },
  {
    id: "UYG1NUYGNuy786tNUHG",
    title: "Know the World of Airbus",
    time: "25th Aug, 2023",
  },
  {
    id: "UiujiunoIB1oiuboijb",
    title: "Townhall",
    time: "4th July, 2023",
  },
  {
    id: "ILbo1iubOBYyiukhbii7",
    title: "Indigo Celebration and Address by Remi Maillard",
    time: "20th Aug, 2023",
  },
  {
    id: "UYGNUYGNuy786tN1UHG",
    title: "Know the World of Airbus",
    time: "25th Aug, 2023",
  },
  {
    id: "UiujiunoIBoiuboijb1",
    title: "Townhall",
    time: "4th July, 2023",
  },
  {
    id: "ILboiubOB1Yyiukhbii7",
    title: "Indigo Celebration and Address by Remi Maillard",
    time: "20th Aug, 2023",
  },
  {
    id: "UYGNUYG1Nuy786tNUHG",
    title: "Know the World of Airbus",
    time: "25th Aug, 2023",
  },
  {
    id: "Uiuj1iunoIBoiuboijb",
    title: "Townhall",
    time: "4th July, 2023",
  },
];

const eventHostedList = [
  {
    id: "UYGNUYGN1uy786tNUHG",
    title: "Know the World of Airbus",
    time: "25th Aug, 2023",
  },
  {
    id: "Uiuji1unoIBoiuboijb",
    title: "Townhall",
    time: "4th July, 2023",
  },
  {
    id: "ILboiubOB3Yyiukhbii7",
    title: "Indigo Celebration and Address by Remi Maillard",
    time: "20th Aug, 2023",
  },
  {
    id: "UYGNUYG4Nuy786tNUHG",
    title: "Know the World of Airbus",
    time: "25th Aug, 2023",
  },
  {
    id: "UiujiunoI6Boiuboijb",
    title: "Townhall",
    time: "4th July, 2023",
  },
  {
    id: "ILboiubOB7Yyiukhbii7",
    title: "Indigo Celebration and Address by Remi Maillard",
    time: "20th Aug, 2023",
  },
  {
    id: "UY2GNUYGNuy786tNUHG",
    title: "Know the World of Airbus",
    time: "25th Aug, 2023",
  },
  {
    id: "UiujiunoIBoiubo6ijb",
    title: "Townhall",
    time: "4th July, 2023",
  },
  {
    id: "ILboiubOB2Yyiukhbii7",
    title: "Indigo Celebration and Address by Remi Maillard",
    time: "20th Aug, 2023",
  },
];

const UserProfile = () => {
  return (
    <>
      <Toolbar
        className={classes.toolbar}
        display={"flex"}
        height={"80"}
        style={{
          justifyContent: "center",
          color: "#ffffff",
          background: "#00205B",
        }}
      >
        <div>
          <span>HELLO USER!</span>
        </div>
      </Toolbar>
      <div className={classes.container}>
        <UserProfileCard />
        <UserHistoryCard
          attendedHistoryList={eventAttendedList}
          hostedHistoryList={eventHostedList}
        />
      </div>
    </>
  );
};

export default UserProfile;
