import React, { useState } from 'react'
import "./option.css";
import {
  FaRegTrashAlt,
  FaImages,
  FaRegTimesCircle,
  FaPlusCircle
} from "react-icons/fa";
import { Tooltip as ReactTooltip } from "react-tooltip";

const Option = (props) => {
  const [quizFields, setQuizFields] = useState([
    {
      question: "",                   //contains text question and image url 
      questionImage:"",
      questionType: "text",
      optionType: "mcq",
      options: [{ option: "", ans: "false" }],
    },
  ]);

  //change fields on typing (type 1 for question; 2 for options; 3 for question type; 4 for option type; 5 for image)
  const handleChange = (event, index, type, indey) => {
    if (type === 1) {
      let data = [...quizFields];
      data[index][event.target.name] = event.target.value;
      setQuizFields(data);
    } else if (type === 2) {
      let data = [...quizFields];
      data[index]["options"][indey][event.target.name] = event.target.value;
      setQuizFields(data);
    } else if (type === 3) {
      let data = [...quizFields];
      data[index]["questionType"] = event.target.value;
      setQuizFields(data);
    } else if (type === 4) {
      let data = [...quizFields];
      data[index]["optionType"] = event.target.value;
      setQuizFields(data);
    } else if (type === 5) {
      let data = [...quizFields];
      data[index]["questionImage"] = URL.createObjectURL(event.target.files[0]);

      setQuizFields(data);
    } else if (type === 6) {
      console.log("click");
      console.log(index, indey);
      let data = [...quizFields];
      data[index]["options"].map((row, index) => {
        row.ans = "false";
      });
      data[index]["options"][indey]["ans"] = "true";
      setQuizFields(data);
    } else {
      console.log("click");
      console.log(index, indey);
      let data = [...quizFields];
      if (data[index]["options"][indey]["ans"] === "false"){
        data[index]["options"][indey]["ans"] = "true";
      }else{
        data[index]["options"][indey]["ans"] = "false";
      }
      setQuizFields(data);
    }
  };

  //add a new question
  const addQuestions = (e) => {
    // e.preventDefault();
    let object = {
      question: "",
      questionImage: "",
      questionType: "text",
      optionType: "mcq",
      options: [{ option: "", ans: "" }],
    };
    console.log("Add question");
    setQuizFields([...quizFields, object]);
  };

  //add a new option field
  const addFields = (e, index) => {
    e.preventDefault();
    let object = { option: "", ans: "false" };
    console.log("Add field " + index);
    let tempData = [...quizFields];
    console.log(tempData[index]);
    tempData[index]["options"].push(object);
    setQuizFields(tempData);
  };

  //remove a question
  const removeQuestion = (e, index) => {
    e.preventDefault();
    console.log("delete option " + index);
    let data = [...quizFields];
    data.splice(index, 1);
    setQuizFields(data);
  };

  //remove a option
  const removeFields = (e, index, indey) => {
    e.preventDefault();
    console.log("delete option " + indey);
    let data = [...quizFields];
    data[index]["options"].splice(indey, 1);
    setQuizFields(data);
  };

  return (
    <>
      <h3 className="h3">Add questions to your quiz</h3>
      <div className="containerQuestion">
        {quizFields.map((quiz, index) => {
          return (
            <div className="questionContainer" key={index}>
              <div className="questionContainerHeader">
                <label htmlFor="inp" className="inp">
                  <input
                    className="textBox"
                    type="text"
                    placeholder="Enter question"
                    value={quiz.question}
                    name="question"
                    id="inp"
                    onChange={(event) => handleChange(event, index, 1, 0)}
                  ></input>
                </label>
                <div className="customerFileBtn">
                  <label className="label">
                    <input
                      type="file"
                      className="form-control"
                      id="inputGroupFile"
                      onChange={(event) => handleChange(event, index, 5, 0)}
                    />
                    <span>
                      <FaImages className="imageIcon" />
                    </span>
                  </label>
                </div>
                <select
                  className="selectOptionType"
                  onChange={(event) => handleChange(event, index, 4, 0)}
                >
                  <option value="mcq">🔘 Multiple choice</option>
                  <option value="multiple">✅ Checkboxes</option>
                </select>
              </div>

              {quiz.questionImage === "" ? null : (
                <img className="questionImage" src={quiz.questionImage} alt='Question img'/>
              )}

              <div className="questionContainerBody">
                {quizFields[index]["options"].map((opt, indey) => {
                  return (
                    <div className="optionContainer" key={indey}>
                      {quiz.optionType === "mcq" ? (
                        <div>
                          <input
                            type="radio"
                            name="address"
                            value={opt.ans}
                            checked={opt.ans === "true"}
                            onChange={(event) =>
                              handleChange(event, index, 6, indey)
                            }
                          />{" "}
                          <label className="optionLabel">
                            Mark the correct option
                          </label>{" "}
                        </div>
                      ) : (
                        <div>
                          <input
                            type="checkbox"
                            value={opt.ans}
                            checked={opt.ans === "true"}
                            onChange={(event) =>
                              handleChange(event, index, 7, indey)
                            }
                          />{" "}
                          <label className="optionLabel">
                            Mark the correct options
                          </label>{" "}
                        </div>
                      )}
                      <label htmlFor="inp" className="inp">
                        <input
                          className="optionTextContainer"
                          name="option"
                          placeholder="option value"
                          onChange={(event) =>
                            handleChange(event, index, 2, indey)
                          }
                          value={opt.option}
                        />
                      </label>
                      {
                        quizFields[index]["options"].length >= 2 ? <button
                          data-tooltip-id="remove-option-tooltip"
                          className="removeBtn"
                          onClick={(e) => removeFields(e, index, indey)}
                        >
                          <FaRegTimesCircle className="deleteIcon" />
                        </button> : null
                      }
                      <ReactTooltip
                        id="remove-option-tooltip"
                        place="bottom"
                        content="Remove option"
                      />
                    </div>
                  );
                })}
                <button
                  className="addOptionBtn"
                  onClick={(e) => addFields(e, index)}
                >
                  {quiz.optionType === "mcq"
                    ? "🔘 Add more options"
                    : "✅ Add more options"}
                </button>
                <div className="saperatorLine"></div>
              </div>

              <div className="questionFooter">
                <button
                  data-tooltip-id="delete-question-tooltip"
                  className="removeBtn"
                  onClick={(e) => removeQuestion(e, index)}
                >
                  <FaRegTrashAlt className="deleteIcon" />
                </button>
                <ReactTooltip
                  id="delete-question-tooltip"
                  place="bottom"
                  content="Delete question"
                />
                {index === quizFields.length - 1 ? (
                  <>
                  <div className="verticalSeperator"></div>
                  <button className="addQuestionBtn" onClick={addQuestions}>
                    <FaPlusCircle className="addIcon" />
                  </button>
                  </>
                ) : null}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default Option