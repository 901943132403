import React from 'react'
import Option from '../../../components/question/Option';
//import "./CreateQuiz.css";

const CreateQuiz = () => {
  return (
    <>
      <Option />
    </>
  );
}

export default CreateQuiz
